import "./App.css";

import React, { useState, useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { Auth } from "aws-amplify";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import CheckIcon from '@mui/icons-material/Check';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { blue } from '@mui/material/colors';

const API_BASE_URL = "https://api.gotaac.it/reservations";

const Reservations = (props) => {
  
  const { signOut } = props;

  dayjs.extend(customParseFormat);

  // Profile menu

  const [anchorElProfile, setAnchorElProfile] = useState(null);

  const handleOpenProfileMenu = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setAnchorElProfile(null);
  };

  const showProfileScreen = () => {
    alert("Funzione non abilitata");
    handleCloseProfileMenu();
  };

  // Fetch and list reservations

  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPastReservations, setShowPastReservations] = useState(false);

  const fetchReservations = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const result = await axios(API_BASE_URL + `/?username=${user.username}`);
    setReservations(result.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchReservations();
  }, []);

  const getDateFormatted = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    return `${day}/${month}`;
  };

  // Handle calendar actions

  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [calendarDate, setCalendarDate] = useState(null);

  const handleOpenCalendarMenu = (event) => {
    setAnchorElCalendar(event.currentTarget);
  };

  const handleCloseCalendarMenu = (calendarDate) => {
    setAnchorElCalendar(null);
    setCalendarDate(calendarDate);
  };

  // Change reservation

  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [parties, setParties] = useState("");
  const [notes, setNotes] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [confirmed, setConfirmed] = useState("true");
  const [username, setUsername] = useState("");
  const [uuid, setUuid] = useState("");
  const [dateTime, setDateTime] = useState({
    date: "",
    time: "",
  });

  function clearReservationData() {
    setName("");
    setDate("");
    setTime("");
    setParties("");
    setNotes("");
    setEmail("");
    setPhone("");
    setConfirmed("true");
    setUsername("");
    setUuid("");
    setDateTime("");
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const reservationData = {
      name: name,
      date: dateTime.date,
      time: dateTime.time,
      parties: parseInt(parties),
      notes: notes,
      email: email,
      phone: phone,
      confirmed: confirmed,
      username: user.username,
    };
    if (uuid !== "") {
      reservationData.uuid = uuid;
    }
    console.log(reservationData)
    try {
      const response = await axios.post(API_BASE_URL, reservationData);
      console.log(response)
      if (response.data.status === 200 && response.data.data === "create_success") {
        fetchReservations();
        closeCreateReservationScreen();
      } else {
        alert("Non è stato possibile aggiungere / modificare la prenotazione");
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  async function replyClientReservation(confirm) {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const reservationData = {
      name: name,
      date: dateTime.date,
      time: dateTime.time,
      parties: parseInt(parties),
      notes: notes,
      email: email,
      phone: phone,
      confirmed: confirm,
      username: user.username,
    };
    if (uuid !== "") {
      reservationData.uuid = uuid;
    }
    console.log(reservationData)
    try {
      const response = await axios.post(API_BASE_URL, reservationData);
      console.log(response)
      if (response.data.status === 200 && response.data.data === "create_success") {
        fetchReservations();
        closeCreateReservationScreen();
      } else {
        alert("Non è stato possibile rispondere alla prenotazione");
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  // Handle change reservation screen

  const [changeReservationScreen, setChangeReservationScreen] = useState(false);
  const [allowDeleteReservation, setAllowDeleteReservation] = useState(false);
  const [allowConfirmationClient, setAllowConfirmationClient] = useState(false);

  async function changeReservation(reservation, calendarDate) {
    if (!reservation && !calendarDate) {
      setChangeReservationScreen(true);
    } else if (!reservation && calendarDate) {
      setDate(dayjs(calendarDate));
      setDateTime({
        date: calendarDate.format("YYYY-MM-DD"),
      });
      setChangeReservationScreen(true);
    } else if (reservation && !calendarDate) {
      setName(reservation.name);
      setDate(dayjs(reservation.date));
      setTime(dayjs(reservation.time, "HH:mm"));
      setParties(reservation.parties);
      setNotes(reservation.notes);
      setEmail(reservation.email);
      setPhone(reservation.phone);
      setConfirmed(reservation.confirmed);
      setUsername(reservation.username);
      setUuid(reservation.uuid);
      setDateTime({
        date: reservation.date,
        time: reservation.time,
      });
      if (reservation.confirmed === "undefined") {
        setAllowConfirmationClient(true);
      }
      else {
        setAllowDeleteReservation(true);
      }
      setChangeReservationScreen(true);
    }
  }

  async function closeCreateReservationScreen() {
    clearReservationData();
    setChangeReservationScreen(false);
  }

  // Delete reservation

  async function deleteReservation() {
    setLoading(true);
    const response = await axios.delete(API_BASE_URL + `/?uuid=${uuid}&username=${username}`);
    if (response.status !== 200 && response.data !== "delete_success") {
      alert("Non è stato possibile cancellare la prenotazione");
    }
    fetchReservations();
    setLoading(false);
    closeCreateReservationScreen();
  }

  // Render components

  function renderReservationsList(reservations) {

    const sortedReservations = reservations
      .sort((a, b) => {
        const dateA = new Date(a.date + ' ' + a.time);
        const dateB = new Date(b.date + ' ' + b.time);
        return dateA - dateB;
      })
      .filter((reservation) => {
        const now = new Date();
        const reservationDate = new Date(reservation.date + ' ' + reservation.time);
        if (showPastReservations) {
          return reservationDate <= now;
        } else {
          return reservationDate >= now;
        }
      });
  
    return (
      <List>
        <Button variant="text" sx={{ paddingTop: '15px', paddingBottom: '15px' }} fullWidth onClick={() => changeReservation(null, calendarDate)}>
          Nuova prenotazione
        </Button>
        <Divider />
        { !calendarDate ? (
          <>{ !showPastReservations ? (
            <Button variant="text" sx={{ paddingTop: '15px', paddingBottom: '15px' }} fullWidth onClick={() => setShowPastReservations(!showPastReservations)}>
              Vedi prenotazioni passate
            </Button>
          ) : (
            <Button variant="text" sx={{ paddingTop: '15px', paddingBottom: '15px' }} fullWidth onClick={() => setShowPastReservations(!showPastReservations)}>
              Vedi prenotazioni future
            </Button>
          )}
          <Divider /></>
        ) : <></>}
        {sortedReservations.map((reservation) => (
          <div key={reservation.uuid} onClick={() => changeReservation(reservation, null)}>
            <ListItem sx={{ paddingTop: '15px', paddingBottom: '15px' }}>

              {reservation.confirmed === "true" && <CheckIcon className="mr-15" color="success" sx={{ width: '0.7em' }} />}
              {reservation.confirmed === "false" && <CloseIcon className="mr-15" color="error" sx={{ width: '0.7em' }} />}
              {reservation.confirmed === "undefined" && <QuestionMarkIcon className="mr-15" sx={{ width: '0.7em' }} />}
              <Avatar sx={{ width: '1.55em', height: '1.55em', background: blue[300], fontWeight: 'bold', borderRadius: '15%' }}>{reservation.parties}</Avatar>
              <div className="flex ml-15">
                <Typography>{reservation.name}</Typography>
                {reservation.notes ? <QuestionAnswerIcon className="ml-15" sx={{ width: '0.7em' }} /> : <></>}
              </div>
              <ListItemText align="right">
                <Typography>
                  {getDateFormatted(reservation.date)} alle {reservation.time}
                </Typography>
              </ListItemText>
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
    );
  }
  
  function renderCreateReservation() {
    return (
      <div>
        <form className="create-reservation-form" onSubmit={handleSubmit}>
          <TextField id="outlined-basic" label="Nome" variant="outlined" value={name} onChange={(event) => setName(event.target.value)} fullWidth />
          <br />
          <br />
          <MobileDatePicker format="DD MMMM" value={dayjs(date)} label="Giorno" onChange={(date) => setDateTime({ ...dateTime, date: date.format("YYYY-MM-DD") })} sx={{ width: "100%" }} />
          <br />
          <br />
          <MobileTimePicker format="HH:mm" defaultValue={dayjs(time)} onChange={(time) => setDateTime({ ...dateTime, time: time.format("HH:mm") })} inputFormat="HH:mm" label="Ora" mask="__:__" disableMaskedInput={false} minutesStep={5} sx={{ width: "100%" }} />
          <br />
          <br />
          <TextField id="outlined-basic" label="Ospiti" variant="outlined" value={parties} onChange={(event) => setParties(event.target.value)} fullWidth />
          <br />
          <br />
          <TextField id="outlined-basic" label="Note" multiline variant="outlined" value={notes} onChange={(event) => setNotes(event.target.value)} fullWidth />
          <br />
          <br />
          {!allowConfirmationClient ? (
            <>
            {allowDeleteReservation ? (
              <div>
                <Button variant="contained" fullWidth color="error" onClick={() => deleteReservation()}>
                  Elimina
                </Button>
              </div>
            ) : (
              <div></div>
            )}
            <Button variant="contained" type="submit" fullWidth color="success">
              Conferma
            </Button>
            </>
          ) : (
            <>
            <Button variant="contained" fullWidth color="success" onClick={() => replyClientReservation("true")}>
              Accetta prenotazione
            </Button>
            <br />
            <br />
            <Button variant="contained" fullWidth color="error" onClick={() => replyClientReservation("false")}>
              Rifiuta prenotazione
            </Button>
            </>
          )}
          <br />
          <br />
        </form>
      </div>
    );
  }

  if (loading) {
    return (
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            {changeReservationScreen ? (
              <div>
                <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={() => closeCreateReservationScreen()}>
                  <CloseIcon />
                </IconButton>
              </div>
            ) : (
              <div>
                {calendarDate ? (
                  <div>
                    <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={() => setCalendarDate(null)}>
                      <MenuIcon />
                    </IconButton>
                  </div>
                ) : (
                  <div>
                    <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={handleOpenCalendarMenu}>
                      <CalendarMonthIcon />
                    </IconButton>
                  </div>
                )}
              </div>
            )}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElCalendar}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={Boolean(anchorElCalendar)}
            >
              <DateCalendar value={calendarDate} onChange={(calendarDate) => handleCloseCalendarMenu(calendarDate)} />
            </Menu>
            {calendarDate ? (
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {getDateFormatted(calendarDate)}
              </Typography>
            ) : (
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Prenotazioni
              </Typography>
            )}
            <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" color="inherit" onClick={handleOpenProfileMenu}>
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElProfile}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElProfile)}
              onClose={handleCloseProfileMenu}
            >
              <MenuItem onClick={showProfileScreen}>Profilo</MenuItem>
              <MenuItem onClick={signOut}>Esci</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </Box>

      <Box>{changeReservationScreen ? renderCreateReservation() : <div>{!calendarDate ? renderReservationsList(reservations) : renderReservationsList(reservations.filter((reservation) => dayjs(reservation.date).isSame(calendarDate, "day")))}</div>}</Box>
    </div>
  );
};

export default Reservations;
