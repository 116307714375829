import React from 'react';
import ReactDOM from 'react-dom/client';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/it';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { itIT as coreItIT } from '@mui/material/locale';
import { itIT } from '@mui/x-date-pickers/locales';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './index.css';

import Reservations from './Reservations.js';
import Client from './Client.js';
import reportWebVitals from './reportWebVitals';

import { Amplify, I18n } from "aws-amplify";
import awsExports from "./aws-exports";
import { Authenticator, translations } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
Amplify.configure(awsExports);
I18n.setLanguage('it');
I18n.putVocabularies(translations);
I18n.putVocabularies({
  it: {
    'Enter your Password': "Password",
    'Please confirm your Password': "Password",
    'Enter your Email': "Email",
    'User does not exist.': "Utente non registrato",
    "PreSignUp failed with error username_length.": "Il nome utente deve essere di 4 cifre",
    "PreSignUp failed with error name 'username' is not defined.": "Il nome utente deve essere di 4 cifre",
    "Your passwords must match": "Le password non coincidono",
    "Incorrect username or password.": "Credenziali errate"
  },
});

const theme = createTheme(
  itIT, // x-date-pickers translations
  coreItIT, // core translations
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={
                <Authenticator variation='modal' initialState="signIn" hideSignUp={true}>
                {({ signOut }) => (
                  <Reservations signOut={signOut} />
                )}
                </Authenticator>
            } />
            <Route path="/:client" element={<Client />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </LocalizationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
