import "./App.css";

import React, { useState, useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useParams } from 'react-router-dom';
import { MuiTelInput } from 'mui-tel-input'

import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const API_BASE_URL = "https://api.gotaac.it/reservations";

const Client = () => {
  
  dayjs.extend(customParseFormat);
  const { client } = useParams();

  // Fetch and list reservations

  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState("");
  const [clientName, setClientName] = useState("");

  useEffect(() => {
    // TO DO ==> AUTOMATE PICKING OF UUID BASED ON CLIENT NAME FROM SEPARATE DYNAMO TABLE
    if (client === "test"){
      setUsername("e4422052-2490-42fa-a970-da86d792ef06");
      setClientName("TEST")
      setLoading(false);
    }
    else {
      alert("PICK A CLIENT!")
    }
  }, []);

  // Change reservation

  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [parties, setParties] = useState("");
  const [notes, setNotes] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [confirmed, setConfirmed] = useState("undefined");
  const [uuid, setUuid] = useState("");
  const [dateTime, setDateTime] = useState({
    date: "",
    time: "",
  });

  function clearReservationData() {
    setName("");
    setDate("");
    setTime("");
    setParties("");
    setNotes("");
    setEmail("");
    setPhone("");
    setConfirmed("undefined");
    setUuid("");
    setDateTime("");
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const reservationData = {
      name: name,
      date: dateTime.date,
      time: dateTime.time,
      parties: parseInt(parties),
      notes: notes,
      email: email,
      phone: phone,
      confirmed: confirmed, 
      username: username,
    };
    if (uuid !== "") {
      reservationData.uuid = uuid;
    }
    console.log(reservationData)
    try {
      const response = await axios.post(API_BASE_URL, reservationData);
      if (response.data.status === 200 && response.data.data === "create_success") {
        confirmReservation();
      } else {
        alert("Non è stato possibile aggiungere / modificare la prenotazione");
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePhoneChange = (newValue) => {
    setPhone(newValue)
  }

  // Handle change reservation screen

  async function confirmReservation() {
    clearReservationData();
    alert("COMPLIMENTI!")
  }

  // Render components
  
  function renderCreateReservation() {
    return (
      <div>
        <p>{clientName}</p>
        <form className="create-reservation-form" onSubmit={handleSubmit}>
          <TextField id="outlined-basic" label="Nome" variant="outlined" value={name} onChange={(event) => setName(event.target.value)} fullWidth />
          <br />
          <br />
          <MobileDatePicker format="DD MMMM" value={dayjs(date)} label="Giorno" onChange={(date) => setDateTime({ ...dateTime, date: date.format("YYYY-MM-DD") })} sx={{ width: "100%" }} />
          <br />
          <br />
          <MobileTimePicker format="HH:mm" defaultValue={dayjs(time)} onChange={(time) => setDateTime({ ...dateTime, time: time.format("HH:mm") })} inputFormat="HH:mm" label="Ora" mask="__:__" disableMaskedInput={false} minutesStep={5} sx={{ width: "100%" }} />
          <br />
          <br />
          <TextField id="outlined-basic" label="Ospiti" variant="outlined" value={parties} onChange={(event) => setParties(event.target.value)} fullWidth />
          <br />
          <br />
          <TextField id="outlined-basic" label="Email" multiline variant="outlined" value={email} onChange={(event) => setEmail(event.target.value)} fullWidth />
          <br />
          <br />
          <MuiTelInput value={phone} onChange={handlePhoneChange} defaultCountry="IT" fullWidth />
          <br />
          <br />
          <TextField id="outlined-basic" label="Note" multiline variant="outlined" value={notes} onChange={(event) => setNotes(event.target.value)} fullWidth />
          <br />
          <br />
          <FormControlLabel required control={<Checkbox />} label="Acconsento al salvataggio delle mie informazioni per la gestione della prenotazione" />
          <br />
          <br />
          <FormControlLabel control={<Checkbox />} label="Voglio ricevere offerte esclusive e comunicazioni riguardo eventi e altre iniziative" />
          <br />
          <br />
          <Button variant="contained" type="submit" fullWidth color="success">
            Conferma
          </Button>
          <br />
          <br />
        </form>
      </div>
    );
  }

  if (loading) {
    return (
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  return (
    <div>
      <Box>{renderCreateReservation()}</Box>
    </div>
  );
};

export default Client;
